import { useModal } from '@providers/ModalProvider';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { AuthContextValue, useAuthState } from '@src/providers/AuthProvider';
import {
  NotificationContextValue,
  useNotificationState,
} from '@src/providers/NotificationProvider';
import { MD_SCREEN_IN_PX } from '@src/utils/constants';
import { AuthScreenType } from '@src/utils/enums';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent, useEffect, useState } from 'react';

const HeaderDrawer = dynamic(() => import('./HeaderDrawer'));
const Menu = dynamic(() => import('@mui/material/Menu'));
const Authentication = dynamic(() => import('@components/modal/authentication/Authentication'));

// Assets
import LanguageSelector from '@components/language/LanguageSelector';
import { customImageLoader } from '@src/utils/media';
import { useTranslation } from 'react-i18next';
import IconBurgerSVG from '/public/ic-burger-menu.svg';
import IconInboxSVG from '/public/ic-inbox-noti.svg';
import IconDefaultProfileSVG from '/public/ic-profile-default.svg';

export default function ProfileMenu() {
  const { setModalContent } = useModal();
  const { isAuthenticated, user, signOut } = useAuthState() as AuthContextValue;
  const { hasUnreadCommissions, hasUnReadJobs } =
    useNotificationState() as NotificationContextValue;
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation('common');

  const router = useRouter();

  const [isTemporaryDrawerOpen, setIsTemporaryDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Decide to open temporary drawer depending on the width of the screen
  const [isDrawerInUse, setIsDrawerInUse] = useState(false);

  const isProfileMenuOpen = Boolean(anchorEl);

  /* Functions */

  const handleOpenMenuOrDrawer = (event: MouseEvent<HTMLElement>) => {
    // If drawer is in use, open temporary drawer
    if (isDrawerInUse) {
      setIsTemporaryDrawerOpen(true);
    } else {
      // Otherwise, open the profile menu
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseTemporaryDrawer = () => {
    setIsTemporaryDrawerOpen(false);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const updateNumColumns = () => {
    // Correspond to config breakpoints
    if (window.innerWidth < MD_SCREEN_IN_PX) {
      setIsDrawerInUse(true);
    } else {
      setIsDrawerInUse(false);
    }
  };

  /* Effects */

  // Decide to open temporary drawer depending on the width of the screen
  useEffect(() => {
    updateNumColumns();
    window.addEventListener('resize', updateNumColumns);
    return () => {
      window.removeEventListener('resize', updateNumColumns);
    };
  }, []);

  return (
    <div>
      <button
        type="button"
        className="flex flex-row relative shrink-0 items-center h-8 pl-3 pr-3 md:pr-1.5
          rounded-full border border-greyscale-g3 hover:z-10 hover:shadow-md"
        onClick={handleOpenMenuOrDrawer}
      >
        {/* <Image alt="burger menu" src="/ic-burger-menu.svg" width={20} height={20} /> */}
        <div className="flex items-center justify-center h-5 w-5">
          <IconBurgerSVG />
        </div>

        {!isMobile && (
          // Still need hidden md:flex to avoid the flashing of desktop header on mobile
          <div
            className={`hidden md:flex justify-center rounded-full relative h-6 w-6 ml-3 bg-greyscale-g3 ${
              !user ? 'p-0.5' : ''
            }`}
          >
            {/* Unauthenticated */}
            {!user && (
              <div className="flex items-center justify-center h-5 w-5">
                <IconDefaultProfileSVG />
              </div>
            )}
            {/* Authenticated and have profile picture */}
            {!!user && user.profile_picture_url && (
              <Image
                alt={`Profile photo of ${user?.displayName}`}
                className="rounded-full"
                layout="fill"
                objectFit="cover"
                src={user?.profile_picture_url || ''}
                sizes="24px"
                loader={customImageLoader}
              />
            )}
            {/* Authenticated but no profile picture */}
            {!!user && !user.profile_picture_url && (
              <div className="flex items-center justify-center rounded-full w-full h-full bg-secondary-s3 text-s">
                {user?.displayName.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
        )}

        {(hasUnreadCommissions === true || hasUnReadJobs === true) && (
          <div className="absolute -top-1 right-0 text-m font-semibold">
            {/* <Image alt="Notification" src="/ic-inbox-noti.svg" width={10} height={10} /> */}
            <div className="flex items-center justify-center h-2 w-2">
              <IconInboxSVG />
            </div>
          </div>
        )}
      </button>

      {/* Menu Or Header Drawer */}

      {isMobile && (
        <div className="block">
          <HeaderDrawer
            isTemporaryDrawerOpen={isTemporaryDrawerOpen}
            handleCloseTemporaryDrawer={handleCloseTemporaryDrawer}
          />
        </div>
      )}

      {!isMobile && (
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          open={isProfileMenuOpen}
          onClose={handleCloseProfileMenu}
          onClick={handleCloseProfileMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
              mt: 1,
              borderRadius: '8px',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <div className="flex flex-col">
            {!isAuthenticated && (
              <button
                className="flex hover:bg-greyscale-g1 py-2 px-4"
                onClick={() => {
                  setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
                }}
              >
                <span className="text-s-tall font-semibold">{t('Đăng ký/Đăng nhập')}</span>
              </button>
            )}
            {isAuthenticated && (
              <div className="flex flex-col">
                <Link href={`/users/${user?.username}`} passHref prefetch={false}>
                  <a
                    id="link-profile-menu-artist-profile"
                    href="replace"
                    className="hover:bg-greyscale-g1 py-2 px-4 mb-2"
                  >
                    <p className="text-s-tall font-semibold">{t('Trang cá nhân')}</p>
                  </a>
                </Link>
                <div className="h-px bg-greyscale-g3" />
                <div className="my-2 flex flex-col">
                  <div className="py-2 px-4">
                    <p className="text-content-t300 text-xs uppercase font-semibold">
                      {t('Dành cho khách hàng')}
                    </p>
                  </div>
                  <Link href="/buyer/contracts" passHref prefetch={false}>
                    <a
                      id="link-header-artist-onboarding"
                      href="replace"
                      className="hover:bg-greyscale-g1 py-2 px-4"
                    >
                      <div className="flex">
                        <p className="text-s-tall font-semibold">Commission</p>
                        {hasUnreadCommissions && (
                          <div className="ml-2 text-m font-semibold">
                            <div className="flex items-center justify-center w-2 h-2">
                              <IconInboxSVG />
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                  </Link>
                  <Link href="/buyer/jobs" passHref prefetch={false}>
                    <a
                      id="link-header-artist-onboarding"
                      href="replace"
                      className="hover:bg-greyscale-g1 py-2 px-4"
                    >
                      <p className="text-s-tall font-semibold">{t('Brief đã gửi')}</p>
                    </a>
                  </Link>
                </div>
                <div className="h-px bg-greyscale-g3" />
                {user?.isArtist && (
                  <div>
                    <div className="my-2 flex flex-col">
                      <div className="py-2 px-4">
                        <p className="text-content-t300 text-xs uppercase font-semibold">
                          {t('Dành cho hoạ sĩ')}
                        </p>
                      </div>
                      <Link href="/artist/contracts" passHref prefetch={false}>
                        <a
                          id="link-header-artist-onboarding"
                          href="replace"
                          className="hover:bg-greyscale-g1 py-2 px-4"
                        >
                          <p className="text-s-tall">{t('Quản lý commission')}</p>
                        </a>
                      </Link>
                      <Link href="/artist/jobs" passHref prefetch={false}>
                        <a
                          id="link-header-artist-onboarding"
                          href="replace"
                          className="hover:bg-greyscale-g1 py-2 px-4"
                        >
                          <div className="flex">
                            <p className="text-s-tall">{t('Xem brief khách gửi riêng bạn')}</p>
                            {hasUnReadJobs && (
                              <div className="ml-2 text-m font-semibold">
                                <div className="flex items-center justify-center w-2 h-2">
                                  <IconInboxSVG />
                                </div>
                              </div>
                            )}
                          </div>
                        </a>
                      </Link>
                      <Link href="/artist/proposals" passHref>
                        <a
                          id="link-header-artist-onboarding"
                          href="replace"
                          className="hover:bg-greyscale-g1 py-2 px-4"
                        >
                          <p className="text-s-tall">{t('Quản lý đơn ứng')}</p>
                        </a>
                      </Link>
                    </div>
                    <div className="h-px bg-greyscale-g3" />
                  </div>
                )}
                {!user?.isArtist && (
                  <div>
                    <div className="my-2 flex flex-col">
                      <div className="py-2 px-4">
                        <p className="text-content-t300 text-xs uppercase font-semibold">
                          {t('Dành cho hoạ sĩ')}
                        </p>
                      </div>
                      <Link href="/become-a-seller" passHref prefetch={false}>
                        <a
                          id="link-header-artist-onboarding"
                          href="replace"
                          className="hover:bg-greyscale-g1 py-2 px-4"
                        >
                          <p className="text-s-tall">{t('Trở thành hoạ sĩ')}</p>
                        </a>
                      </Link>
                    </div>
                    <div className="h-px bg-greyscale-g3" />
                  </div>
                )}
                <button
                  className="flex hover:bg-greyscale-g1 py-2 px-4 mt-2"
                  onClick={() => {
                    setModalContent(<LanguageSelector />);
                  }}
                >
                  <p className="text-content-t100 text-s-tall">{t('Chọn ngôn ngữ')}</p>
                </button>
                <button
                  className="flex hover:bg-greyscale-g1 py-2 px-4"
                  onClick={() => {
                    signOut();
                    router.reload();
                  }}
                >
                  <p className="text-content-t100 text-s-tall">{t('Đăng xuất')}</p>
                </button>
              </div>
            )}
          </div>
        </Menu>
      )}
    </div>
  );
}
